.bellies {
  margin-top: -100px;
}

.bellies .belliesContainer {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.bellies .belly {
  width: 220px;
  height: 220px;
  display: inline-block;
  border-radius: 210px;
  background-color: white;
  overflow: hidden;
}

.bellies .belly img {
  height: 200px;
  margin-top: 20px;
  vertical-align: bottom;
}
