.pictures {
  margin: 20px 10%;
}

.pictures ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pictures ul li {
  margin-bottom: 40px;
}

.pictures ul li img {
  width: 100%;
  border-radius: 10px;
  vertical-align: bottom;
}

.pictures ul li:last-child {
  font-size: 12px;
}

.pictures ul li:last-child span {
  padding: 0 5px;
  font-size: 30px;
  color: red;
  vertical-align: middle;
}
