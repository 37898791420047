.bellies {
  margin-top: -100px;
}

.bellies .belliesContainer {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.bellies .belly {
  width: 220px;
  height: 220px;
  display: inline-block;
  border-radius: 210px;
  background-color: white;
  overflow: hidden;
}

.bellies .belly img {
  height: 200px;
  margin-top: 20px;
  vertical-align: bottom;
}

.pictures {
  margin: 20px 10%;
}

.pictures ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pictures ul li {
  margin-bottom: 40px;
}

.pictures ul li img {
  width: 100%;
  border-radius: 10px;
  vertical-align: bottom;
}

.pictures ul li:last-child {
  font-size: 12px;
}

.pictures ul li:last-child span {
  padding: 0 5px;
  font-size: 30px;
  color: red;
  vertical-align: middle;
}

body {
  padding: 0;
  margin: 0;
  background: #fffaf3;
  text-align: center;
  font-family: "Aleo";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

input[type="time"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  width: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  background: none;
  padding: 0;
}

@media only screen and (min-width: 600px) {
  .App {
    width: 400px;
    height: 600px;
    margin: 0 auto;
  }
}

.front {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #aa6356;
  background-image: url(/static/media/bg.3ffb12b2.jpg);
  background-size: cover;
}

.time {
  width: 115px;
  height: 44px;
  display: inline-block;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  -webkit-appearance: textfield;
  color: white;
  font-family: inherit;
  font-size: 45px;
  text-align: center;
  font-weight: bold;
}

.time::-webkit-clear-button {
  display: none;
}

.time::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

.center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
}

body .center > * {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.front .name {
  margin-top: -60px;
  color: #d6a00e;
  font-family: "Parisienne", sans-serif;
  font-size: 90px;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) {
  .front .name {
    background: linear-gradient(180deg, #d6a00e 0%, #f5b813 100%);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.privacy {
  color: rgba(255, 255, 255, 0.5);
}

.question {
  color: white;
  font-weight: bold;
  font-size: 24px;
}

.back {
  padding: 30px 15px;
}

.back .name {
  color: #e98f9d;
  font-family: "Parisienne";
  font-size: 70px;
}

.back .nameBirth {
  color: #e98f9d;
  font-size: 24px;
}

.back .all {
  margin: 20px 0;
}

.back .all span {
  display: block;
  color: #e98f9d;
  font-family: "Parisienne";
  font-size: 24px;
  line-height: 20px;
}

.back .all img {
  width: 20px;
  margin-bottom: 10px;
  vertical-align: middle;
}

.back .ago {
  width: 230px;
  margin: 20px 0;
  display: inline-block;
  color: #818181;
  font-size: 16px;
  line-height: 1.5em;
}

